import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import { Button, TablePaginationConfig, message, Form, Input } from "antd";
import { Layout } from "layout";
import {
  QuestionsClient,
  QuestionDto,
  QuestionRelationshipsClient,
  QuestionCategoriesClient,
} from "@api";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import QuestionMenu from "@components/Admin/Question/QuestionMenu";
import ContentTable from "@components/Admin/ContentTable";
import { RcFile } from "antd/lib/upload";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import AsyncSelect from "lib/AsyncSelect";
import LanguageSelectFilter from "@components/Admin/LanguageSelectFilter";

const defaultOrderBy = "created";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "Questions", "Questions");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const [filterLang, setFilterLangId] = useState(
    undefined as string | undefined | null
  );

  const allLanguageFetch = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
      const _init = {
        ...init,
        headers: {
          ...init?.headers,
          "accept-language": "*",
        },
      };
      return fetch(url, _init);
    },
  };
  const questionsClient = new QuestionsClient(undefined, allLanguageFetch);
  const questionRelationshipsClient = new QuestionRelationshipsClient();
  const questionCategoriesClient = new QuestionCategoriesClient();

  const [state, setState] = useState({
    items: [] as QuestionDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const [filterQuestionCategoryId, setFilterQuestionCategoryId] = useState(
    undefined as number | undefined | null
  );
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "Questions")) return;

    setState({
      ...state,
      loading: true,
    });
    // console.log(_keywords, keywords, _keywords ?? keywords);
    try {
      const specificLanguageFetch = {
        fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
          const _init = {
            ...init,
            headers: {
              ...init?.headers,
              "accept-language": filterLang ?? "*",
            },
          };
          return fetch(url, _init);
        },
      };
      const specificQuestionsClient = new QuestionsClient(
        undefined,
        specificLanguageFetch
      );

      const res = await specificQuestionsClient.getQuestionsWithPagination(
        filterQuestionCategoryId,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        _keywords ?? keywords,
        params.pagination.current,
        params.pagination.pageSize,
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );
      setState({
        ...state,
        items: (res.items as QuestionDto[]) || ([] as QuestionDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleDownloadQuestionsContent() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await questionsClient.exportQuestionsContent();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadQuestionsContent(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await questionsClient.uploadQuestionsContent({
        data: f,
        fileName: f.name,
      });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadQuestions() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await questionsClient.exportQuestions();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadQuestions(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await questionsClient.uploadQuestions({ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadChoiceLists() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await questionRelationshipsClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadChoiceLists(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await questionRelationshipsClient.upload({ data: f, fileName: f.name });
      //   fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    // },
    {
      // title: "Question Category",
      title: <FormattedMessage id="QuestionQuestionTableHeaderQuestionCategory" />,
      dataIndex: "categoryAlias",
      key: "categoryAlias",
      sorter: true,
    },
    {
      // title: "ID",
      title: <FormattedMessage id="QuestionQuestionTableHeaderId" />,
      dataIndex: "itemNumber",
      key: "itemNumber",
      sorter: true,
    },
    {
      // title: "Question Content",
      title: <FormattedMessage id="QuestionQuestionTableHeaderQuestionContent" />,
      dataIndex: "content",
      key: "content",
      render: (text, record: QuestionDto) => (
        <ContentTable content={record.content} />
      ),
      className: "content-table-cell",
    },
    {
      // title: "Type",
      title: <FormattedMessage id="QuestionQuestionTableHeaderType" />,
      dataIndex: "type",
      key: "type",
      sorter: true,
      render: (text, record: QuestionDto) => (
        <FormattedMessage id={"QuestionType_" + text} />
      ),
    },
    {
      // title: "Time added",
      title: <FormattedMessage id="QuestionQuestionTableHeaderTimeAdded" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
  ];

  useEffect(() => {
    fetchItems();
  }, [filterQuestionCategoryId, filterLang]);

  return (
    <Layout title="QuestionQuestionNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <QuestionMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords}  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap justify="flex-end">
              {auth.can(ACTION.Download, "Questions") ? (
                <Button type="primary" onClick={handleDownloadQuestions}>
                  <FormattedMessage id="QuestionQuestionContentDownloadButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "Questions") ? (
                <Upload action={handleUploadQuestions}>
                  <Button type="primary">
                    <FormattedMessage id="QuestionQuestionContentUploadButton" />
                  </Button>
                </Upload>
              ) : null}
              {auth.can(ACTION.Download, "QuestionRelationships") ? (
                <Button type="primary" onClick={handleDownloadChoiceLists}>
                  <FormattedMessage id="QuestionQuestionContentDownloadRelationshipButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "QuestionRelationships") ? (
                <Upload action={handleUploadChoiceLists}>
                  <Button type="primary">
                    <FormattedMessage id="QuestionQuestionContentUploadRelationshipButton" />
                  </Button>
                </Upload>
              ) : null}
              {auth.can(ACTION.Download, "QuestionContents") ? (
                <Button type="primary" onClick={handleDownloadQuestionsContent}>
                  <FormattedMessage id="QuestionQuestionContentDownloadContentButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "QuestionContents") ? (
                <Upload action={handleUploadQuestionsContent}>
                  <Button type="primary">
                    <FormattedMessage id="QuestionQuestionContentUploadContentButton" />
                  </Button>
                </Upload>
              ) : null}
            </Space>
          </Space>
          <Space justify="flex-start">
            <AsyncSelect
              onChange={setFilterQuestionCategoryId}
              onUpdate={(pageNumber, pageSize) =>
                questionCategoriesClient.get(
                  null,
                  pageNumber,
                  pageSize,
                  undefined,
                  undefined
                )
              }
              placeholder={
                <FormattedMessage id="QuestionCategoryStaticSelectLabel" />
              }
              labelName={"alias"}
            ></AsyncSelect>
            <LanguageSelectFilter handleAction={setFilterLangId} />
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
